@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Helvetica Neue',
    monospace;
}


html,
body,
#root {
height: 100%;
}

.App{
  display: flex;
  flex-direction: column;
}
.main-video {
  display: flex;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
}

.main-video .full-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: -100;
  background-color: #000;
}

.main-video .video-main {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  transform: translate(-50%, -50%);
  opacity: .6;
  object-fit: cover;
}

.text-carousel {
  display: block;
}

.text-carousel h1,
.text-carousel h2 {
  display: none;
  transition: all 1s;
  transition-timing-function: cubic-bezier(0.71, 0.03, 0.58, 0.99);
  opacity: 0;
  visibility: hidden;
}

.text-carousel h1 div,
.text-carousel h2 div {
  position: relative;
  color: rgba(0, 0, 0, 0);
}

.text-carousel h1.active,
.text-carousel h2.active {
  display: block;
  opacity: 1;
  visibility: visible;
  color: rgba(0, 0, 0, 0);
}

.text-carousel h1.gohide,
.text-carousel h2.gohide {
  opacity: 0;
  visibility: hidden;
}

.text-carousel .word span {
  margin: 0 .65rem;
}

@media (max-width: 768px) {
  .text-carousel .word span {
    margin: 0 .4rem;
  }
  .carousel.flickity-enabled.is-draggable {
    display: table;
  }
  .carousel.flickity-enabled.is-draggable .flickity-viewport {
    display: table;
    overflow: auto;
  }
  .carousel.flickity-enabled.is-draggable .flickity-viewport .flickity-slider {
    display: table;
    overflow: auto;
  }
  .carousel.flickity-enabled.is-draggable .flickity-viewport .flickity-slider .carousel-cell {
    display: table;
    overflow: auto;
  }
  .films-carousel .carousel-container .carousel .carousel-cell .caption {
    bottom: auto;
    top: -19vw;
  }
  .films-carousel .carousel-container .carousel .carousel-cell.is-selected .caption em {
    opacity: 1;
  }
  .films-carousel .carousel-container .carousel .carousel-cell.is-selected .caption .btn {
    position: relative;
    top: 52vw;
  }
  .films-carousel .carousel-container .carousel-nav {
    position: relative;
    top: 15vw;
  }
  .films-carousel .carousel-container .carousel .carousel-cell img {
    margin: 0 0;
    width: 100vw;
  }
}

.word, .word1, .word2 {
  position: relative;
  display: inline-block;
}

.word1 {
  margin-right: .5rem;
}

.word .blur-1s {
  -webkit-animation: header-blur 1s ease-in;
          animation: header-blur 1s ease-in;
}

.word .blur-2s {
  -webkit-animation: header-blur 1.6s ease-in;
          animation: header-blur 1.6s ease-in;
}

.word .blur-3s {
  -webkit-animation: header-blur 1.7s ease-in;
          animation: header-blur 1.7s ease-in;
}

.word .blur-4s {
  -webkit-animation: header-blur 1.8s ease-in;
          animation: header-blur 1.8s ease-in;
}

.word .blur-5s {
  -webkit-animation: header-blur 1.9s ease-in;
          animation: header-blur 1.9s ease-in;
}

.word .blur-6s {
  -webkit-animation: header-blur 1.1s ease-in;
          animation: header-blur 1.1s ease-in;
}

.word .blur-7s {
  -webkit-animation: header-blur 1.2s ease-in;
          animation: header-blur 1.2s ease-in;
}

.word .blur-8s {
  -webkit-animation: header-blur 1.3s ease-in;
          animation: header-blur 1.3s ease-in;
}

.word .blur-9s {
  -webkit-animation: header-blur 1.4s ease-in;
          animation: header-blur 1.4s ease-in;
}

.word .blur-10s {
  -webkit-animation: header-blur 1.5s ease-in;
          animation: header-blur 1.5s ease-in;
}

.word .blur-11s {
  -webkit-animation: header-blur 1.6s ease-in;
          animation: header-blur 1.6s ease-in;
}

.word .blur-12s {
  -webkit-animation: header-blur 1.7s ease-in;
          animation: header-blur 1.7s ease-in;
}

.word .blur-13s {
  -webkit-animation: header-blur 1.8s ease-in;
          animation: header-blur 1.8s ease-in;
}

.word .blur-14s {
  -webkit-animation: header-blur 1.9s ease-in;
          animation: header-blur 1.9s ease-in;
}

.word .blur-15s {
  -webkit-animation: header-blur 2s ease-in;
          animation: header-blur 2s ease-in;
}

.word .blur-16s {
  -webkit-animation: header-blur 2.1s ease-in;
          animation: header-blur 2.1s ease-in;
}

.word .blur-17s {
  -webkit-animation: header-blur 2.2s ease-in;
          animation: header-blur 2.2s ease-in;
}

.word .blur-18s {
  -webkit-animation: header-blur 2.3s ease-in;
          animation: header-blur 2.3s ease-in;
}

.word .blur-19s {
  -webkit-animation: header-blur 2.4s ease-in;
          animation: header-blur 2.4s ease-in;
}

.word .blur-20s {
  -webkit-animation: header-blur 2.5s ease-in;
          animation: header-blur 2.5s ease-in;
}

.word > span {
  font-size: 1rem;
  font-weight: bold;
  color: white;
  font-family: 'Montserrat','sans serif';
  letter-spacing: .6rem;
}

.carousel {
  text-align: center;
  text-transform: uppercase;
  -ms-grid-row-align: center;
  align-self: center;
  margin-left: 37%;
  margin-right: 30%;
}

.react-player {
  position: absolute;
  /* left: 0; */
}

@media (max-width: 560px) {
  .react-player {
    top: 45%;
    width: 100% !important;
    height: 50% !important;
  }
}

@media (min-width: 560px) and (max-width: 960px) {
  .react-player {
    top: 50vh;
    margin-left: 7rem;
    width: 90% !important;
    height: 50% !important;
  }
}

@media (min-width: 960px) {
  .react-player {
    margin-left: 7rem;
    top: 0;
    height: 100% !important;
    width: 92% !important;
  }
}
/*# sourceMappingURL=Video.css.map */
.word, .word1, .word2 {
  position: relative;
  display: inline-block;
}

.word h1, .word1 h1, .word2 h1 {
  font-size: 0;
}

.word1 {
  margin-right: .5rem;
}

.word .blur-1s {
  -webkit-animation: header-blur 1s ease-in;
          animation: header-blur 1s ease-in;
}

.word .blur-2s {
  -webkit-animation: header-blur 1.6s ease-in;
          animation: header-blur 1.6s ease-in;
}

.word .blur-3s {
  -webkit-animation: header-blur 1.7s ease-in;
          animation: header-blur 1.7s ease-in;
}

.word .blur-4s {
  -webkit-animation: header-blur 1.8s ease-in;
          animation: header-blur 1.8s ease-in;
}

.word .blur-5s {
  -webkit-animation: header-blur 1.9s ease-in;
          animation: header-blur 1.9s ease-in;
}

.word .blur-6s {
  -webkit-animation: header-blur 1.1s ease-in;
          animation: header-blur 1.1s ease-in;
}

.word .blur-7s {
  -webkit-animation: header-blur 1.2s ease-in;
          animation: header-blur 1.2s ease-in;
}

.word .blur-8s {
  -webkit-animation: header-blur 1.3s ease-in;
          animation: header-blur 1.3s ease-in;
}

.word .blur-9s {
  -webkit-animation: header-blur 1.4s ease-in;
          animation: header-blur 1.4s ease-in;
}

.word .blur-10s {
  -webkit-animation: header-blur 1.5s ease-in;
          animation: header-blur 1.5s ease-in;
}

.word .blur-11s {
  -webkit-animation: header-blur 1.6s ease-in;
          animation: header-blur 1.6s ease-in;
}

.word .blur-12s {
  -webkit-animation: header-blur 1.7s ease-in;
          animation: header-blur 1.7s ease-in;
}

.word .blur-13s {
  -webkit-animation: header-blur 1.8s ease-in;
          animation: header-blur 1.8s ease-in;
}

.word .blur-14s {
  -webkit-animation: header-blur 1.9s ease-in;
          animation: header-blur 1.9s ease-in;
}

.word .blur-15s {
  -webkit-animation: header-blur 2s ease-in;
          animation: header-blur 2s ease-in;
}

.word .blur-16s {
  -webkit-animation: header-blur 2.1s ease-in;
          animation: header-blur 2.1s ease-in;
}

.word .blur-17s {
  -webkit-animation: header-blur 2.2s ease-in;
          animation: header-blur 2.2s ease-in;
}

.word .blur-18s {
  -webkit-animation: header-blur 2.3s ease-in;
          animation: header-blur 2.3s ease-in;
}

.word .blur-19s {
  -webkit-animation: header-blur 2.4s ease-in;
          animation: header-blur 2.4s ease-in;
}

.word .blur-20s {
  -webkit-animation: header-blur 2.5s ease-in;
          animation: header-blur 2.5s ease-in;
}

.article-home {
  -webkit-animation: header-blur ease-in;
          animation: header-blur ease-in;
}

@-webkit-keyframes header-blur {
  0% {
    filter: blur(7px);
    -moz-filter: blur(7px);
    -webkit-filter: blur(7px);
    opacity: 0;
  }
  /* 15% {
		filter:blur(5px);
		-moz-filter:blur(5px);
		-webkit-filter:blur(5px);
	}
	25% {
		filter:blur(5px);
		-moz-filter:blur(5px);
		-webkit-filter:blur(5px);
		opacity: 0;
	}*/
  50% {
    filter: blur(7px);
    -moz-filter: blur(7px);
    -webkit-filter: blur(7px);
    opacity: 0;
  }
  /*
	75% {
		filter:blur(5px);
		-moz-filter:blur(5px);
		-webkit-filter:blur(5px);
	}*/
  100% {
    filter: blur(0);
    -moz-filter: blur(0);
    -webkit-filter: blur(0);
    opacity: .9;
  }
}

@keyframes header-blur {
  0% {
    filter: blur(7px);
    -moz-filter: blur(7px);
    -webkit-filter: blur(7px);
    opacity: 0;
  }
  /* 15% {
		filter:blur(5px);
		-moz-filter:blur(5px);
		-webkit-filter:blur(5px);
	}
	25% {
		filter:blur(5px);
		-moz-filter:blur(5px);
		-webkit-filter:blur(5px);
		opacity: 0;
	}*/
  50% {
    filter: blur(7px);
    -moz-filter: blur(7px);
    -webkit-filter: blur(7px);
    opacity: 0;
  }
  /*
	75% {
		filter:blur(5px);
		-moz-filter:blur(5px);
		-webkit-filter:blur(5px);
	}*/
  100% {
    filter: blur(0);
    -moz-filter: blur(0);
    -webkit-filter: blur(0);
    opacity: .9;
  }
}

@media (max-width: 960px) {
  .main-article {
    padding: 16.66vw;
    background-color: #fff;
    color: #2f2f2f;
  }
  .main-article i {
    font-size: 1.2rem;
  }
  .main-article p {
    margin: 2rem 0;
  }
}

@media (min-width: 960px) {
  .main-article {
    padding: 16.66vw;
    background-color: #fff;
    color: #2f2f2f;
  }
  .main-article i {
    font-size: 1.2rem;
  }
  .main-article p {
    margin: 2rem 0;
  }
}

.word > span.art {
  color: black !important;
  letter-spacing: 0.1rem !important;
  font-size: .8rem;
}

@media (max-width: 480px) {
  .word > span.article-1 {
    display: flex;
    flex-wrap: wrap;
  }
}

.word > span.article-home {
  font-size: 1.3rem;
  color: black !important;
  letter-spacing: 0.1rem !important;
}

.word > span.article {
  color: black !important;
  letter-spacing: 0.1rem !important;
}

.inner__word {
  color: black;
  text-align: center;
  font-size: .8rem;
}

.card_product {
  display: flex;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  justify-content: center;
}

@media (min-width: 560px) {
  .card_product {
    margin-left: 7rem;
  }
}

.main-title {
  color: #2f2f2f;
  text-transform: uppercase;
  text-align: center;
  font-family: 'Montserrat';
  margin-left: 7rem;
  display: flex;
  line-height: 1.5rem;
}

@media (max-width: 348px) {
  .main-title {
    padding: 100px 20px;
    font-size: 1rem;
  }
}

@media (min-width: 348px) and (max-width: 425px) {
  .main-title {
    font-size: 1.5rem;
    padding: 100px 10px;
  }
}

@media (min-width: 425px) and (max-width: 525px) {
  .main-title {
    font-size: 1.7rem;
    padding: 100px 10px;
  }
}

@media (min-width: 525px) and (max-width: 780px) {
  .main-title {
    font-size: 1.2rem;
    padding: 100px 20px;
  }
}

@media (min-width: 780px) and (max-width: 960px) {
  .main-title {
    padding: 50px;
    font-size: 1.5rem;
  }
}

@media (min-width: 960px) {
  .main-title {
    padding: 50px;
    font-size: 1.8rem;
  }
}

.arrow-title {
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-name: slidein;
          animation-name: slidein;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

@-webkit-keyframes slidein {
  from {
    width: 0%;
  }
  to {
    width: 40%;
  }
}

@keyframes slidein {
  from {
    width: 0%;
  }
  to {
    width: 40%;
  }
}

#productions {
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#productions::-webkit-scrollbar {
  display: none;
}
/*# sourceMappingURL=Article.css.map */
.article-fop {
  transform: translate3d(0px, 5.34052px, 0px);
}

.layer-1 {
  z-index: 12;
  background-position-y: 100%;
  background-position-x: 0;
}

.film-img {
  max-width: 100%;
  width: 58.31vw;
  height: 32.8vw;
  background-repeat: no-repeat;
  background-position: 0 100%;
  background-size: cover;
  visibility: visible;
  right: 6.3vw;
  transition: ease-in-out .5s;
  cursor: url(/core/styles/inhale/images/play.svg) 32 32, url(/core/styles/inhale/images/play.svg) 32 32, auto !important;
  cursor: -webkit-image-set(url(/core/styles/inhale/images/play.cur) 1x, url(/core/styles/inhale/images/play.cur) 2x) 32 32, pointer !important;
  overflow: hidden;
  z-index: 10;
  transition: none;
  -moz-transition: none;
  -webkit-transition: none;
}

.film-img .layer-1 {
  position: absolute;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: 0 100%;
  background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  display: block;
  width: 100%;
  height: 100%;
}

.films {
  padding: 8.33vw 0 4.166vw 0;
  background-color: #1e1e1e;
  position: relative;
}

.films .article-fop {
  position: absolute;
  right: 15rem;
  top: 8.5rem;
  width: 220px;
  height: 220px;
  opacity: .8;
  z-index: 300;
}

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.film-box {
  align-items: center;
  justify-content: center;
  margin: 0;
}

.film-text {
  color: #d4e5e3;
  position: relative;
  margin: 0 10vw;
  padding: 6vw;
  z-index: 100;
  visibility: hidden;
}

.film-text h2 {
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  font-size: 2.8rem;
  letter-spacing: 6px;
  margin-bottom: 0;
  visibility: visible;
}

.film-text span.subheader {
  font-size: 1.4rem;
  visibility: visible;
}

i {
  font-size: 1.2rem;
}

.film-text p {
  color: #737373;
  font-family: 'Montserrat', sans-serif;
  margin: 2rem 0 4rem 0;
  visibility: visible;
  display: inline-block;
  width: auto;
  width: 70vw;
}

.btn-card {
  float: right;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.film-text a.btn {
  visibility: visible;
}

.btn-dark:hover {
  color: #181818;
  border: 1px solid black;
}

.btn-dark:hover .arrow {
  color: black;
}

.btn:focus, .btn:hover {
  text-decoration: none;
}

.hvr-sweep-to-right {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  position: relative;
  transition-property: color;
  transition-duration: 0.3s;
}

.hvr-sweep-to-right:hover i {
  color: black;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
}

.btn-dark {
  font-size: 1.2rem;
  text-align: left;
  color: #fff;
  border: 1px solid black;
  background-color: #181818 !important;
  border-radius: 0;
  padding: .8rem 2.7rem .8rem 1.2rem;
  width: calc(38vw - 1px);
  transition: ease-in-out .3s;
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: white;
}

.btn {
  align-items: center;
  font-weight: 400;
  padding: .5rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

.hvr-sweep-to-right:hover:before, .hvr-sweep-to-right:focus:before, .hvr-sweep-to-right:active:before {
  transform: scaleX(1);
  color: black;
}

.hvr-sweep-to-right:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  transform: scaleX(0);
  transform-origin: 0 50%;
  transition-property: transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}

.i-word {
  position: absolute;
  width: auto;
  left: 10rem;
  height: 10rem;
  bottom: 5vh;
  transition: all 1s ease;
  visibility: visible;
}

.f-up-word {
  position: absolute;
  width: auto;
  left: 15rem;
  height: 8rem;
  bottom: 8vh;
  transition: all 1s ease;
  visibility: visible;
}

.f-down-word {
  position: absolute;
  width: auto;
  left: 14.9rem;
  height: 8rem;
  bottom: 5vh;
  transition: all 1s ease;
  visibility: visible;
}

.all-word:hover {
  transform: scale(1.5);
}

.jarallax {
  position: relative;
  z-index: 0;
}

.filmplay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  visibility: visible;
}

.filmplay-1 {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -100;
}

.filmplay-2 {
  width: 1548.09px;
  height: 1270.8px;
  max-width: none;
  max-height: none;
  margin: -258.4px 0px 0px -402.444px;
  z-index: -1;
  transform: translate3d(0px, -43.44px, 0px);
}
/*# sourceMappingURL=VideoArticle.css.map */
a {
  text-decoration: none !important;
}

.main-footer {
  height: 100%;
  width: 100%;
  background: linear-gradient(360deg, #2d62a4, #1e1e1e);
  background-position: 200% 200%;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  font-size: 10vw;
  padding: 40px;
  bottom: 0;
  flex-flow: column;
  color: white;
  -webkit-animation: fop 12s infinite;
          animation: fop 12s infinite;
}

.text-footer {
  display: flex;
  bottom: 0;
  padding: 50px;
}

.social-media {
  list-style-type: none;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  color: #fff;
  font-size: .8rem;
  font-weight: bold;
  margin-right: 1.5vw;
  line-height: 3em;
  vertical-align: middle;
}

@media (max-width: 500px) {
  .social-media {
    display: block;
  }
}

@media (min-width: 500px) {
  .social-media {
    display: flex;
  }
}

.social-media li {
  margin: 20px;
  cursor: pointer;
}

.social-media > li > a :hover {
  text-decoration: none;
}

ul.social-media > li > a::before {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background-color: white;
  position: absolute;
  top: 100%;
  left: -.1rem;
  transition: all 0.3s;
  transition-timing-function: cubic-bezier(0.5, 0.4, 0.3, 1);
}

ul.social-media > li > a::before:hover {
  width: 100%;
}

.footer-content {
  padding-top: 8.33vw;
  padding-left: 4.166vw;
  padding-right: 4.166vw;
  padding-bottom: 8.33vw;
}

.footer-content .word, .footer-content .word1, .footer-content .word2 {
  position: relative;
}

@media (min-width: 500px) {
  .footer-content .word, .footer-content .word1, .footer-content .word2 {
    display: inline-block;
  }
}

@media (max-width: 500px) {
  .footer-content .word, .footer-content .word1, .footer-content .word2 {
    display: flex;
    flex-wrap: wrap;
  }
}

.footer-content .word1 {
  margin-right: .5rem;
}

.footer-content .word .blur-1s {
  -webkit-animation: header-blur 1s ease-in;
          animation: header-blur 1s ease-in;
}

.footer-content .word .blur-2s {
  -webkit-animation: header-blur 1.6s ease-in;
          animation: header-blur 1.6s ease-in;
}

.footer-content .word .blur-3s {
  -webkit-animation: header-blur 1.7s ease-in;
          animation: header-blur 1.7s ease-in;
}

.footer-content .word .blur-4s {
  -webkit-animation: header-blur 1.8s ease-in;
          animation: header-blur 1.8s ease-in;
}

.footer-content .word .blur-5s {
  -webkit-animation: header-blur 1.9s ease-in;
          animation: header-blur 1.9s ease-in;
}

.footer-content .word .blur-6s {
  -webkit-animation: header-blur 1.1s ease-in;
          animation: header-blur 1.1s ease-in;
}

.footer-content .word .blur-7s {
  -webkit-animation: header-blur 1.2s ease-in;
          animation: header-blur 1.2s ease-in;
}

.footer-content .word .blur-8s {
  -webkit-animation: header-blur 1.3s ease-in;
          animation: header-blur 1.3s ease-in;
}

.footer-content .word .blur-9s {
  -webkit-animation: header-blur 1.4s ease-in;
          animation: header-blur 1.4s ease-in;
}

.footer-content .word .blur-10s {
  -webkit-animation: header-blur 1.5s ease-in;
          animation: header-blur 1.5s ease-in;
}

.footer-content .word .blur-11s {
  -webkit-animation: header-blur 1.6s ease-in;
          animation: header-blur 1.6s ease-in;
}

.footer-content .word .blur-12s {
  -webkit-animation: header-blur 1.7s ease-in;
          animation: header-blur 1.7s ease-in;
}

.footer-content .word .blur-13s {
  -webkit-animation: header-blur 1.8s ease-in;
          animation: header-blur 1.8s ease-in;
}

.footer-content .word .blur-14s {
  -webkit-animation: header-blur 1.9s ease-in;
          animation: header-blur 1.9s ease-in;
}

.footer-content .word .blur-15s {
  -webkit-animation: header-blur 2s ease-in;
          animation: header-blur 2s ease-in;
}

.footer-content .word .blur-16s {
  -webkit-animation: header-blur 2.1s ease-in;
          animation: header-blur 2.1s ease-in;
}

.footer-content .word .blur-17s {
  -webkit-animation: header-blur 2.2s ease-in;
          animation: header-blur 2.2s ease-in;
}

.footer-content .word .blur-18s {
  -webkit-animation: header-blur 2.3s ease-in;
          animation: header-blur 2.3s ease-in;
}

.footer-content .word .blur-19s {
  -webkit-animation: header-blur 2.4s ease-in;
          animation: header-blur 2.4s ease-in;
}

.footer-content .word .blur-20s {
  -webkit-animation: header-blur 2.5s ease-in;
          animation: header-blur 2.5s ease-in;
}

.footer-content h2 {
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 2.5rem;
}

.footer-content .lower-text {
  margin-top: 60px;
  font-weight: 500;
  font-size: 1.2rem;
  text-transform: none;
}

/* FOOTER COLOR ROTATION */
@-webkit-keyframes fop {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes fop {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
/*# sourceMappingURL=Footer.css.map */
a {
  color: white !important;
}

.all-header {
  visibility: visible;
}

.all-header-on {
  visibility: hidden;
}

.main-header {
  padding: 2vw 4vw;
  font-size: .7rem;
  width: 100%;
  text-transform: uppercase;
  background-color: transparent;
}

.main-header:hover {
  color: white;
  cursor: pointer;
}

@media (max-width: 960px) {
  .main-header ul > .left-header {
    visibility: hidden;
  }
}

.left-header-main {
  margin-left: 2rem;
}

.logo-header {
  height: 35px;
  width: auto;
  color: white;
  -webkit-animation: logo-fade 2s ease-in;
          animation: logo-fade 2s ease-in;
  max-width: 100px;
  height: 7rem;
  object-fit: contain;
  object-position: left;
  margin-left: 5rem;
  margin-top: 3rem;
}

.logo-header ul {
  width: 200px;
}

.logo-header ul > h4 {
  color: #d4e5e3;
  padding: 1vw 0 !important;
  margin: 0 1.6vw;
  position: relative;
  display: inline-block;
}

.left-header {
  margin-top: 5rem;
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: .7rem;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.left-header > a:hover {
  font-weight: 500;
  transition: all 0.3s;
  transition-timing-function: cubic-bezier(0.5, 0.4, 0.3, 1);
}

.left-h1 {
  padding: 0 .66rem;
  -webkit-animation: link1-fade 3s ease-in;
          animation: link1-fade 3s ease-in;
}

.left-h2 {
  padding: 0 .66rem;
  -webkit-animation: link2-fade 3s ease-in;
          animation: link2-fade 3s ease-in;
}

.left-h3 {
  -webkit-animation: link3-fade 3s ease-in;
          animation: link3-fade 3s ease-in;
}

@-webkit-keyframes logo-fade {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes logo-fade {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes link1-fade {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes link1-fade {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes link2-fade {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes link2-fade {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes link3-fade {
  0% {
    opacity: 0;
  }
  85% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes link3-fade {
  0% {
    opacity: 0;
  }
  85% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.btn-outline:hover::before {
  width: 100%;
  left: 0;
}

.btn-outline::before {
  content: '';
  position: absolute;
  width: 90%;
  height: 100%;
  border: 1px solid white;
  left: +5%;
  top: 0;
  transition: all 0.3s;
  transition-timing-function: cubic-bezier(0.5, 0.4, 0.3, 1);
}

.btn-outline {
  position: relative;
  border: 1px solid white;
  border: none;
  border-radius: 0;
  padding: .5rem 2rem;
  -webkit-animation: link3-fade 3s ease-in;
          animation: link3-fade 3s ease-in;
}

.btn:focus, .btn:hover {
  text-decoration: none;
}
/*# sourceMappingURL=Header.css.map */
.overlay {
  position: fixed;
  background: linear-gradient(180deg, #2d62a4, #1e1e1e);
  top: 0;
  left: -100%;
  width: 93.7vw;
  height: 100%;
  opacity: 1;
  visibility: hidden;
  transition: all .9s;
  transition-timing-function: cubic-bezier(0.5, 0.4, 0.3, 1);
  overflow: hidden;
  z-index: 999;
}

@media screen and (max-width: 768px) {
  .overlay {
    z-index: 9;
  }
}

.overlay::before {
  content: '';
  position: absolute;
  top: 0;
  right: -10%;
  background-color: black;
  z-index: 999;
  width: 50%;
  height: 100%;
  transition: all 0.6s;
  transition-timing-function: cubic-bezier(0.5, 0.4, 0.3, 1);
}

.overlay.open {
  opacity: 1;
  visibility: visible;
  z-index: 999;
}

@media (min-width: 1800px) {
  .overlay.open {
    width: 100%;
  }
}

.overlay.open {
  left: 6rem;
}

.overlay.open::before {
  width: 0%;
  left: 0;
}

.overlay .overlay-content > ul {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  width: 100%;
  text-align: left;
  list-style: none;
}

.overlay .overlay-content > ul > li a.in-production {
  color: black !important;
}

.overlay .overlay-content > ul > li {
  display: block;
  margin-top: 2vh;
}

.overlay .overlay-content > ul > li > a {
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-weight: bold;
  margin-bottom: 3rem;
  display: block;
  border-left: 2px solid;
}

@media (max-width: 1260px) {
  .overlay .overlay-content > ul > li > a {
    font-size: .9rem;
  }
}

.overlay .overlay-content > ul > li > ul {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left;
}

.overlay .overlay-content > ul > li > ul > li {
  display: block;
}

.overlay .overlay-content > ul > li > ul > li > a {
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  font-size: .6rem;
  font-weight: bold;
  letter-spacing: .5px;
  color: #d4e5e3;
  text-decoration: none;
  display: inline-block;
  margin-bottom: .5rem;
  position: relative;
  margin: .5vh 0;
}

.overlay .overlay-content > ul > li > ul > li > a::before {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background-color: white;
  position: absolute;
  top: 100%;
  left: -.1rem;
  transition: all 0.3s;
  transition-timing-function: cubic-bezier(0.5, 0.4, 0.3, 1);
}

.overlay .overlay-content > ul > li > ul > li > a:hover::before {
  width: 100%;
}

.overlay-content {
  position: relative;
  padding: 0 7.9vw;
  top: 30vh;
}

.overlay a {
  text-decoration: none;
}

.overlay a:hover,
.overlay a:focus {
  color: white;
}

@media only screen and (max-width: 576px) and (min-width: 320px) {
  .hamburger {
    position: absolute;
    width: 40px;
    height: 4px;
    transition-timing-function: ease;
    transition-duration: .15s;
    transition-property: transform;
    border-radius: 4px;
    background-color: #fff;
    z-index: inherit 9999;
  }
  .hamburger::before {
    position: absolute;
    width: 10px;
    height: 4px;
    transition-timing-function: ease;
    transition-duration: .15s;
    transition-property: transform;
    border-radius: 4px;
    background-color: #fff;
    top: -10px;
    transition: top 0.1s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s, transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
}

.hamburger-all {
  display: inline-block;
  position: fixed;
  z-index: 9999 !important;
  overflow: visible;
  margin: 0;
  padding: 30px;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: .15s;
  transition-property: opacity,-webkit-filter;
  transition-property: opacity,filter;
  transition-property: opacity,filter,-webkit-filter;
  text-transform: none;
  color: inherit;
  border: 0;
  left: 0;
  background-color: transparent;
  z-index: 99999;
}

.hamburger-box {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}

.hamburger {
  position: absolute;
  width: 40px;
  height: 4px;
  transition-timing-function: ease;
  transition-duration: .15s;
  transition-property: transform;
  border-radius: 4px;
  background-color: #fff;
  z-index: inherit 9999;
}

.hamburger::before {
  position: absolute;
  width: 10px;
  height: 4px;
  transition-timing-function: ease;
  transition-duration: .15s;
  transition-property: transform;
  border-radius: 4px;
  background-color: #fff;
  top: -10px;
  transition: top 0.1s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s, transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger {
  display: block;
  margin-top: -2px;
}

.hamburger.on {
  position: fixed;
}

@media (max-width: 960px) {
  .overlay .overlay-content > ul {
    flex-direction: column;
  }
  .overlay .overlay-content > ul > li > a {
    margin: 1vh 0;
  }
  .overlay-content {
    top: 3vh;
  }
}

@media (min-width: 960px) and (max-width: 1080px) {
  .overlay .overlay-content > ul > li > a {
    font-size: 1rem !important;
  }
}

@media (min-width: 1080px) and (max-width: 1600px) {
  .overlay .overlay-content > ul > li > a {
    font-size: 1.1rem !important;
  }
}

@media (min-width: 1600px) {
  .overlay .overlay-content > ul > li > a {
    font-size: 1.6rem !important;
  }
}
/*# sourceMappingURL=Navbar.css.map */
.container_main {
  height: 100vh;
  overflow-y: scroll;
}

.homepage {
  scroll-snap-align: center;
}

.homepage:nth-child(n) {
  background: none;
}

.homepage:nth-child(2n) {
  background: white;
}

.homepage:nth-child(3n) {
  background: none;
}

.homepage .char {
  display: inline-block;
  -webkit-animation-delay: calc(0.25s + var(--char-index) * 0.1s);
  animation-delay: calc(0.25s + var(--char-index) * 0.1s);
  transition: all 0.25s calc(0.25s + var(--char-index) * 0.1s);
}
/*# sourceMappingURL=Home.css.map */
.list-offer {
  font-weight: 500;
}

.list-offer h6 {
  font-size: .5rem;
}

.all-label {
  height: 100vh;
  overflow: auto;
  overflow-x: hidden;
  overflow: hidden;
  -ms-scroll-snap-type: y mandatory;
      scroll-snap-type: y mandatory;
}

.label {
  top: 0;
  font-size: calc(var(--font-size) * 1px);
  background: #f4f4f4;
  display: flex;
  /* flex-wrap: wrap; */
  flex-flow: column;
  position: relative;
  align-items: center;
  justify-content: center;
  justify-items: center;
  text-align: justify;
  box-shadow: 0 5px 12px -5px #000000;
  padding: 1.2rem;
}

@media (max-width: 960px) {
  .label {
    margin-left: 7rem;
  }
}

@media (min-width: 960px) {
  .label {
    margin-left: 45%;
    position: absolute;
  }
}

@media (min-width: 350px) {
  .label {
    opacity: 0.7;
  }
}

@media (max-width: 560px) and (min-width: 500px) {
  .label {
    height: auto;
  }
}

@media (max-width: 500px) and (min-width: 475px) {
  .label {
    height: auto;
  }
}

@media (max-width: 475px) {
  .label {
    height: auto;
  }
}

.label h2 {
  text-align: center;
}

.label h3 {
  font-size: .7rem;
  color: black;
}

.label:hover, .label:active {
  opacity: 1;
}

.button-outline:hover::before {
  width: 100%;
  left: 0;
}

.button-outline::before {
  content: '';
  position: absolute;
  width: 93%;
  height: 100%;
  border-top: 1px solid black;
  color: #000000;
  left: +3%;
  font-weight: 300;
  top: 0;
  transition: all 0.3s;
  transition-timing-function: cubic-bezier(0.5, 0.4, 0.3, 1);
  -webkit-animation: fade-in-keyframes 3s ease-in;
          animation: fade-in-keyframes 3s ease-in;
}

.button-outline {
  position: relative;
  cursor: pointer;
  color: black;
  font-weight: 300;
  font-size: .7rem;
  border: 1px solid black;
  border: none;
  border-radius: 0;
  padding: .5rem 2rem;
}

@-webkit-keyframes fade-in-keyframes {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-in-keyframes {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.button:focus, .button:hover {
  text-decoration: none;
}

@media (min-width: 560px) {
  iframe.video {
    left: 7rem;
  }
}

@media (max-width: 560px) {
  iframe.video {
    left: 0;
  }
}

img {
  height: 53rem;
}

.page {
  height: 100vh;
  width: 100vw;
  scroll-snap-align: center;
  font-size: calc(var(--font-size) * 1px);
  font-weight: bold;
  position: relative;
  color: #fff;
  text-align: center;
}

.page ul > li {
  color: black;
  list-style-type: none;
  font-size: .7rem;
}

.page p {
  color: black;
  font-weight: 300;
}

@media (max-width: 960px) and (min-width: 760px) {
  .page p {
    font-size: .9rem;
  }
}

@media (max-width: 760px) and (min-width: 560px) {
  .page p {
    font-size: .8rem;
  }
}

@media (max-width: 560px) and (min-width: 467px) {
  .page p {
    font-size: .65rem;
  }
}

@media (max-width: 467px) and (min-width: 442px) {
  .page p {
    font-size: .63rem;
  }
}

@media (max-width: 442px) and (min-width: 408px) {
  .page p {
    font-size: .58rem;
  }
}

@media (max-width: 408px) and (min-width: 356px) {
  .page p {
    font-size: .55rem;
  }
}

@media (max-width: 356px) {
  .page p {
    font-size: .5rem;
  }
}

.container-1 {
  height: 100vh;
}

.page:nth-child(n) {
  background: white;
}

.page:nth-child(2n) {
  background: white;
}

.page:nth-child(3n) {
  background: white;
}

.page .char {
  display: inline-block;
  -webkit-animation-delay: calc(0.25s + var(--char-index) * 0.1s);
  animation-delay: calc(0.25s + var(--char-index) * 0.1s);
  transition: all 0.25s calc(0.25s + var(--char-index) * 0.1s);
}

@media (max-width: 580px) {
  .img-about {
    width: 100% !important;
    height: 100%;
  }
}

@media (min-width: 580px) and (max-width: 760px) {
  .img-about {
    width: 82%;
    margin-left: 7rem;
    height: 100%;
  }
}

@media (min-width: 760px) {
  .img-about {
    width: 85%;
    height: 100%;
    margin-left: 6rem;
  }
}

.list-offer-main {
  margin: 0;
  padding: 0;
}

.error {
  justify-content: center;
}
/*# sourceMappingURL=About.css.map */
.video_card {
  color: black;
  height: 100%;
  transition: transform 100ms;
}

@media (max-width: 320px) {
  .video_card {
    width: 320px;
    padding: 0;
  }
}

@media (min-width: 320px) and (max-width: 350px) {
  .video_card {
    width: 320px;
    padding: 0;
  }
}

@media (min-width: 350px) and (max-width: 750px) {
  .video_card {
    width: 350px;
    padding: 0;
  }
}

@media (min-width: 750px) {
  .video_card {
    width: 500px;
    padding: 20px;
  }
}

.video_card:hover {
  transform: scale(1.09);
}

.video_card > img {
  height: 100%;
  object-fit: contain;
  width: 100%;
}

.video_card > h2 {
  padding-top: 10px;
  font-size: 1.2rem;
}

.video_card:hover > .videoCard__stats {
  display: inline;
  padding: 1rem;
}

.videoCard__stats {
  display: none;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container-product {
  display: flex;
  height: 100%;
  background: #ffffff;
  height: 100%;
  font-family: 'Helvetica Neue LT W01_41488878';
  font-size: 16px;
  line-height: 26px;
}

.the-arrow {
  width: 1px;
  transition: all 0.2s;
}

.the-arrow.-left {
  position: absolute;
  top: 60%;
  left: 0;
}

.the-arrow.-left > .shaft {
  width: 0;
  background-color: #4c4c4c;
}

.the-arrow.-left > .shaft:before, .the-arrow.-left > .shaft:after {
  width: 0;
  background-color: #4c4c4c;
}

.the-arrow.-left > .shaft:before {
  transform: rotate(0);
}

.the-arrow.-left > .shaft:after {
  transform: rotate(0);
}

.the-arrow.-right {
  top: 3px;
}

.the-arrow.-right > .shaft {
  width: 1px;
  transition-delay: 0.2s;
}

.the-arrow.-right > .shaft:before, .the-arrow.-right > .shaft:after {
  width: 8px;
  transition-delay: 0.3s;
  transition: all 0.5s;
}

.the-arrow.-right > .shaft:before {
  transform: rotate(40deg);
}

.the-arrow.-right > .shaft:after {
  transform: rotate(-40deg);
}

.the-arrow > .shaft {
  background-color: #4c4c4c;
  display: block;
  height: 1px;
  position: relative;
  transition: all 0.2s;
  transition-delay: 0;
  will-change: transform;
}

.the-arrow > .shaft:before, .the-arrow > .shaft:after {
  background-color: #4c4c4c;
  content: '';
  display: block;
  height: 1px;
  position: absolute;
  top: 0;
  right: 0;
  transition: all 0.2s;
  transition-delay: 0;
}

.the-arrow > .shaft:before {
  transform-origin: top right;
}

.the-arrow > .shaft:after {
  transform-origin: bottom right;
}

.animated-arrow {
  display: inline-block;
  color: #4c4c4c;
  font-size: 1.25em;
  font-style: italic;
  text-decoration: none;
  position: relative;
  transition: all 0.2s;
}

.animated-arrow:hover {
  color: #808080;
}

.animated-arrow:hover > .the-arrow.-left > .shaft {
  width: 64px;
  transition-delay: 0.1s;
  background-color: #808080;
}

.animated-arrow:hover > .the-arrow.-left > .shaft:before, .animated-arrow:hover > .the-arrow.-left > .shaft:after {
  width: 8px;
  transition-delay: 0.1s;
  background-color: #808080;
}

.animated-arrow:hover > .the-arrow.-left > .shaft:before {
  transform: rotate(40deg);
}

.animated-arrow:hover > .the-arrow.-left > .shaft:after {
  transform: rotate(-40deg);
}

.animated-arrow:hover > .main {
  transform: translateX(17px);
  transform: translateX(80px);
}

.animated-arrow:hover > .main > .the-arrow.-right > .shaft {
  width: 0;
  transform: translateX(200%);
  transition-delay: 0;
}

.animated-arrow:hover > .main > .the-arrow.-right > .shaft:before, .animated-arrow:hover > .main > .the-arrow.-right > .shaft:after {
  width: 0;
  transition-delay: 0;
  transition: all 0.1s;
}

.animated-arrow:hover > .main > .the-arrow.-right > .shaft:before {
  transform: rotate(0);
}

.animated-arrow:hover > .main > .the-arrow.-right > .shaft:after {
  transform: rotate(0);
}

.animated-arrow > .main {
  display: flex;
  align-items: center;
  transition: all 0.2s;
}

.animated-arrow > .main > .text {
  margin: 0 16px 0 0;
  line-height: 1;
  color: black;
  font-weight: 500;
  font-family: 'Helvetica Neue LT W01_41488878';
}

.animated-arrow > .main > .the-arrow {
  position: relative;
}
/*# sourceMappingURL=CardProduct.css.map */
.card_box {
  position: absolute;
  background-color: white;
  padding: 2rem 1rem 2rem 5rem;
  text-align: right;
  box-shadow: 0px 6px 18px -9px rgba(0, 0, 0, 0.75);
  transition: transform 100ms ease-in;
  z-index: 0;
}

@media (min-width: 1220px) {
  .card_box {
    top: 20vw;
    right: 11vw;
    width: 40vw;
  }
}

@media (min-width: 771px) and (max-width: 1220px) {
  .card_box {
    top: 0;
    right: 20vw;
    width: 60vw;
  }
}

@media (max-width: 771px) {
  .card_box {
    top: 0;
    margin-left: 10rem;
  }
}

.card_box > img {
  position: absolute;
  right: 10%;
  height: 100vh;
}

@media (max-width: 771px) {
  .carousel.flickity-enabled.is-draggable {
    display: none !important;
  }
  .image_mobile {
    width: 330px;
    height: 350px;
    transition: transform 100ms;
  }
  .image_mobile_container {
    display: flex;
    overflow-x: hidden;
    overflow: hidden;
    -ms-scroll-snap-type: y mandatory;
        scroll-snap-type: y mandatory;
    overflow-x: scroll;
  }
}

@media (min-width: 771px) {
  .image_mobile {
    display: none;
  }
}

@media (max-width: 771px) {
  .card_doc {
    top: 10rem;
    margin-left: 10rem;
  }
}

.card {
  background-color: white;
  position: fixed;
  padding: 2rem 1rem 2rem 6.5rem;
  text-align: right;
  z-index: 2;
  box-shadow: 0px 6px 18px -9px rgba(0, 0, 0, 0.75);
  transition: transform 100ms ease-in;
  z-index: 0;
}

@media (min-width: 1220px) {
  .card {
    top: 15vw;
    right: 11vw;
    width: 40vw;
  }
}

@media (min-width: 771px) and (max-width: 1220px) {
  .card {
    right: 0;
    width: 70vw;
    top: 8rem;
  }
}

.card > img {
  position: absolute;
  right: 10%;
  height: 100vh;
}

.main__card {
  height: 80vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 1;
}

.card__info {
  border-radius: 10px;
  background: white;
  padding: 20px;
  padding-top: 20px;
  border: 1;
  z-index: 10;
}

.card__info > h2 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 30px;
  text-transform: uppercase;
}

.card__info > h4 {
  font-size: 1.8vh;
  font-weight: 300;
  margin-top: 8px;
  margin-bottom: 25px;
}

i {
  font-size: 1rem;
}

.carousel {
  margin-left: 7rem;
}

.flickity-prev-next-button.previous {
  right: 100px !important;
}

.flickity-prev-next-button {
  top: 90% !important;
}
/*# sourceMappingURL=Card.css.map */
/*! Flickity v2.1.2
https://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
    position: relative;
  }
  
  .flickity-enabled:focus {
    outline: none;
  }
  
  .flickity-viewport {
    overflow: hidden;
    position: relative;
    height: 100%;
  }
  
  .flickity-slider {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  
  /* draggable */
  
  .flickity-enabled.is-draggable {
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .flickity-enabled.is-draggable .flickity-viewport {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
  }
  
  .flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }
  
  /* ---- flickity-button ---- */
  
  .flickity-button {
    position: absolute;
    background: hsla(0, 0%, 100%, 0.75);
    border: none;
    color: #333;
  }
  
  .flickity-button:hover {
    background: white;
    cursor: pointer;
  }
  
  .flickity-button:focus {
    outline: none;
    box-shadow: 0 0 0 5px #19f;
  }
  
  .flickity-button:active {
    opacity: 0.6;
  }
  
  .flickity-button:disabled {
    opacity: 0.3;
    cursor: auto;
    /* prevent disabled button from capturing pointer up event. #716 */
    pointer-events: none;
  }
  
  .flickity-button-icon {
    fill: #333;
  }
  
  /* ---- previous/next buttons ---- */
  
  .flickity-prev-next-button {
    top: 50%;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    /* vertically center */
    transform: translateY(-50%);
  }
  
  .flickity-prev-next-button.previous {
    left: 10px;
  }
  .flickity-prev-next-button.next {
    right: 10px;
  }
  /* right to left */
  .flickity-rtl .flickity-prev-next-button.previous {
    left: auto;
    right: 10px;
  }
  .flickity-rtl .flickity-prev-next-button.next {
    right: auto;
    left: 10px;
  }
  
  .flickity-prev-next-button .flickity-button-icon {
    position: absolute;
    left: 20%;
    top: 20%;
    width: 60%;
    height: 60%;
  }
  
  /* ---- page dots ---- */
  
  .flickity-page-dots {
    position: absolute;
    width: 100%;
    bottom: -25px;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    line-height: 1;
  }
  
  .flickity-rtl .flickity-page-dots {
    direction: rtl;
  }
  
  .flickity-page-dots .dot {
    display: none;
  }
  
  .flickity-page-dots .dot.is-selected {
    opacity: 1;
  }
  
.logo-header {
  margin-top: 1rem;
}

.logo-header-card {
  position: absolute;
  right: 0;
}

.flickity-prev-next-button {
  top: 80% !important;
}

.social-media-card {
  display: flex;
  transform-origin: center;
  transform: rotate(270deg);
  position: relative;
  list-style-type: none;
  display: flex;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  color: #fff;
  font-weight: bold;
  font-size: .7rem;
  line-height: 3em;
  vertical-align: middle;
}

.social-media-card li {
  margin: 20px;
  cursor: pointer;
}

.social-media-card > a {
  position: relative;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  color: #fff;
  font-size: .9rem;
  font-weight: bold;
  margin-right: 1.5vw;
}

.social-media-card > a::before {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background-color: #fff;
  position: absolute;
  top: 47%;
  left: -.1rem;
  transition: all 0.3s;
  transition-timing-function: cubic-bezier(0.5, 0.4, 0.3, 1);
}

@media (min-width: 560px) and (max-width: 770px) {
  .image_mobile_container_main {
    margin-left: 7rem;
  }
}

.left-bar {
  display: flex;
  position: fixed;
  justify-content: space-between;
  width: 7rem;
  text-align: center;
  flex-direction: column;
  background: linear-gradient(360deg, #2d62a4, #1e1e1e);
  color: #d4e5e3;
  z-index: 1;
}

@media (max-width: 560px) {
  .left-bar {
    height: 24rem;
  }
}

@media (min-width: 560px) {
  .left-bar {
    height: 100%;
  }
}

.btn-card {
  width: calc(28vw - 10px) !important;
}
/*# sourceMappingURL=Documentary.css.map */
